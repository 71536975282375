import { Link, NavLink, useNavigation } from '@remix-run/react'
import { useEffect } from 'react'
import { Hamburger } from './hamburger'
import {
  TbArrowUpRight,
  TbChartBar,
  TbDeviceAnalytics,
  TbMathFunction,
  TbStack2,
  TbTransform,
  TbUpload,
  TbWorld,
} from 'react-icons/tb'
import { useBoolean } from 'usehooks-ts'
import clsx from 'clsx'
import { CallToAction } from '~/utils/a-b'
import { Disclosure } from '../disclosure/disclosure'
import { Header } from '~/components-v2/header/header'
import { Stack } from '~/components-v2'

export const ContentHeader = () => {
  const { value: hasMenu, toggle, setFalse: closeMenu } = useBoolean()
  const navigation = useNavigation()

  useEffect(() => {
    if (hasMenu && navigation.state != 'idle') closeMenu()
  }, [closeMenu, hasMenu, navigation])

  return (
    <Header
      className={clsx(hasMenu && ' is-active')}
      onClickWordmark={closeMenu}
    >
      <button
        className="hamburger-menu"
        aria-pressed={hasMenu}
        onClick={toggle}
      >
        <Hamburger />
      </button>
      <nav className="nav">
        <Disclosure title="Product">
          <NavLink to="/platform">
            <TbWorld /> Platform
          </NavLink>
          <NavLink to="/business-intelligence">
            <TbChartBar /> Business Intelligence
          </NavLink>
          <NavLink to="/embedded-analytics">
            <TbDeviceAnalytics /> Embedded Analytics
          </NavLink>
          <NavLink to="/integrations">
            <TbTransform /> Integrations
          </NavLink>
          <NavLink to="/calculations">
            <TbMathFunction /> Calculations
          </NavLink>
          <NavLink to="/data-modeling">
            <TbStack2 /> Data Modeling
          </NavLink>
          <NavLink to="/data-input">
            <TbUpload />
            Data Input
          </NavLink>
        </Disclosure>
        <Disclosure title="Customers">
          <NavLink to="/customer-case-studies">Customer Case Studies</NavLink>
          <NavLink to="/customer-support">Customer Support</NavLink>
        </Disclosure>
        <NavLink to="/about">About</NavLink>

        <NavLink to="/blog">Blog</NavLink>
        <NavLink to="/security">Security</NavLink>
        <Disclosure title="Learn">
          <a href="https://docs.omni.co" rel="noreferrer" target="_blank">
            Documentation <TbArrowUpRight />
          </a>
          <NavLink to="https://docs.omni.co/guides" target="_blank">
            User Guides <TbArrowUpRight />
          </NavLink>
          <NavLink to="/changelog">Changelog</NavLink>
          <NavLink to="/demos">Demos</NavLink>
          <NavLink to="/compare">Compare</NavLink>
          <NavLink to="https://community.omni.co/" target="_blank">
            Community <TbArrowUpRight />
          </NavLink>
        </Disclosure>

        <Stack row gap={2} className="header-actions">
          <Link className="call-to-action size-md" to="/request-demo">
            Request Demo
          </Link>
          <CallToAction intent="primary" className="size-md" />
        </Stack>
      </nav>
    </Header>
  )
}
