import { Link } from '@remix-run/react'

export const flashActive = true

export const FlashContent = () => (
  <>
    <strong>Omni raises $69M in Series B funding 🥳</strong> |{' '}
    <Link to="/blog/omni-series-b-funding">Learn more</Link>
  </>
)
