import { flashActive, FlashContent } from '~/config/flash'

export const Flash = () =>
  flashActive ? (
    <div className="flash">
      <div className="flash-content">
        <FlashContent />
      </div>
    </div>
  ) : null
