import type { ReactNode, RefObject } from 'react'
import { useEffect, useRef, useState } from 'react'
import { TbChevronDown } from 'react-icons/tb'
import clsx from 'clsx'
import type { StackProps } from '~/components-v2'
import { Stack } from '~/components-v2'
import { useClickAnyWhere, useHover } from 'usehooks-ts'
import { JSX } from 'react'

type DisclosureContentProps = Pick<
  StackProps,
  'className' | 'gap' | 'row' | 'wrap'
>

type DisclosureProps = {
  className?: string
  children: ReactNode

  title: string | JSX.Element

  /**
   * Disclosure content is a `Stack` component, this expose properties on that
   *  component to allow for customization
   */
  contentProps?: DisclosureContentProps
}

/**
 * This *should* be a Popover/Menu that is controlled via hover or click
 */
export const Disclosure = ({
  className,
  title,
  children,
  contentProps = { gap: 0 },
}: DisclosureProps) => {
  const [isOpen, setIsOpen] = useState(false)

  const ref = useRef<HTMLDetailsElement>(null)
  const summaryRef = useRef<HTMLElement>(null)

  const isHover = useHover(ref as RefObject<HTMLDetailsElement>)
  useEffect(() => setIsOpen(isHover), [isHover])
  useClickAnyWhere((event) => {
    if (!isOpen) return
    if (!ref.current || !summaryRef.current) return
    if (summaryRef.current.contains(event.target as Node)) return
    setIsOpen(false)
  })

  return (
    <details ref={ref} open={isOpen} className={clsx(className, 'disclosure')}>
      <summary ref={summaryRef}>
        <span className="disclosure-label">{title}</span>
        <TbChevronDown aria-hidden="true" />
      </summary>
      <Stack
        gap={1}
        {...contentProps}
        className={clsx('disclosure-content', contentProps.className)}
      >
        {children}
      </Stack>
    </details>
  )
}
